import React, { useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneArrowUp, faMobile, faCommentQuestion } from '@fortawesome/pro-solid-svg-icons'
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "components/Layout"
import Seo from 'components/Seo'

/**
 * Renders the root page.
 * @returns {JSX.Element}
 */
const IndexPage = () => {

  // Keeps track of the AppStore link panel
  const [showAppStoreLinks, setShowAppStoreLinks] = useState<boolean>(false);

  /**
   * Renders the main navigation area
   * @returns {JSX.Element}
   */
   const CTAButtons = () => {
    return(
      <nav className="grid grid-cols-2 text-center text-black md:grid-cols-3 border-y-2 md:gap-4 md:border-y-0 border-tecsight-blue">
        <OutboundLink href="tel://0482083810" className="flex flex-col justify-between py-8 font-bold bg-tecsight-yellow md:rounded-lg">
          <FontAwesomeIcon 
            size="2x" 
            className="mr-4 fill-current" 
            icon={faPhoneArrowUp} />
          <span className="pt-6">0482 083 810</span>
        </OutboundLink>
        <OutboundLink href="https://get.teamviewer.com/tecsight" 
        className="flex-col justify-between hidden py-8 font-bold bg-blue-200 md:rounded-lg md:flex">
          <FontAwesomeIcon 
            size="2x" 
            className="mr-4 fill-current" 
            icon={faCommentQuestion} />
          <span className="pt-6">Teamviewer</span>
        </OutboundLink>
        <button 
          className="flex flex-col items-center justify-between p-8 font-bold bg-tecsight-green md:rounded-lg"
          onClick={() => {
            setShowAppStoreLinks(true);
          }}
          >
          <FontAwesomeIcon 
            size="2x" 
            className="fill-current" 
            icon={faMobile} />
          <span className="pt-6">Operations Center</span>
        </button>
      </nav>
    );
  }

  /**
   * Root navigation for the site
   * @returns {JSX.Element}
   */
  const RootLinks = () => {
    return(
      <nav>
        <ul className="p-4 md:grid md:grid-cols-2 md:gap-4">
          <li className="p-2 mb-2 bg-yellow-100 rounded-full shadow-sm md:p-4 md:rounded-md">
            <Link 
              className="flex items-center md:items-start"
              to="/remote-support">
            <StaticImage 
                placeholder="tracedSVG"
                height={50}
                src="../images/logo-remote-support.svg"
                alt="Remote Support"/>
            <div className="ml-4">
              <h2 className="font-semibold text-tecsight-blue">Remote Support</h2>
              <p className="text-sm text-gray-500">Get support via phone or email</p>
            </div>
            </Link>
          </li>
          <li className="p-2 mb-2 bg-yellow-100 rounded-full shadow-sm md:p-4 md:rounded-md">
            <Link 
              className="flex items-center md:items-start"
              to="/field-specialist">
            <StaticImage 
                placeholder="tracedSVG"
                height={50}
                src="../images/logo-field-specialist.svg"
                alt="Field specialist"/>
            <div className="ml-4">
              <h2 className="font-semibold text-tecsight-blue">Field specialist</h2>
              <p className="text-sm text-gray-500">Have our specialists visit you</p>
            </div>
            </Link>
          </li>
          <li className="p-2 mb-2 bg-yellow-100 rounded-full shadow-sm md:p-4 md:rounded-md">
            <Link 
              className="flex items-center md:items-start"
              to="/precision-ag">
            <StaticImage 
                placeholder="tracedSVG"
                height={50}
                src="../images/logo-precision-ag.svg"
                alt="Precision Ag"/>
            <div className="ml-4">
              <h2 className="font-semibold text-tecsight-blue">Precision Ag</h2>
              <p className="text-sm text-gray-500">Technology, data and smart machinery</p>
            </div>
            </Link>
          </li>
          <li className="p-2 mb-2 bg-yellow-100 rounded-full shadow-sm md:p-4 md:rounded-md">
            <Link 
              className="flex items-center md:items-start"
              to="/machine-shed">
            <StaticImage 
                placeholder="tracedSVG"
                height={50}
                src="../images/logo-machinery-shed.svg"
                alt="Field specialist"/>
            <div className="ml-4">
              <h2 className="font-semibold text-tecsight-blue">Machinery Shed</h2>
              <p className="text-sm text-gray-500">Handy resources for your gear</p>
            </div>
            </Link>
          </li>
          <li className="p-2 mb-2 bg-yellow-100 rounded-full shadow-sm md:p-4 md:rounded-md">
            <OutboundLink 
              className="flex items-center md:items-start"
              href="https://www.deere.com.au/en/campaigns/youve-got-what-it-takes/">
            <StaticImage 
                placeholder="tracedSVG"
                height={50}
                src="../images/logo-ygwt.svg"
                alt="Field specialist"/>
            <div className="ml-4">
              <h2 className="font-semibold text-tecsight-blue">JD &mdash; You’ve got what it takes</h2>
              <p className="text-sm text-gray-500">Connect all your equipment</p>
            </div>
            </OutboundLink>
          </li>
        </ul>
      </nav>)
  }

  /**
   * A container with the introductory text 
   * @returns {JSX.Element}
   */
  const IntroText = () => {
    return(
      <section className="p-4 text-gray-700">
        <h1 className="mb-2 text-3xl font-bold text-center text-tecsight-blue">Welcome to TECSight</h1>
        <p className="mb-4 font-semibold text-center">
        TECSight is the support you need to get the most from your John Deere technology.
        </p>
        <p className="mb-4">
          As Hutcheon &amp; Pearce’s specialised team of highly qualified, John Deere trained 
          technicians we provide over-the-phone, on-farm and Precision Ag support to help you 
          seamlessly connect your machine. Using the JD technology, we provide the data insights 
          to improve your farm’s productivity and profitability.          
        </p>
        <p>
          With more and more machines moving toward automation and remote connection, there has 
          never been a better time to use the expertise of TECSight.
        </p>
      </section>
    )
  }

  /**
   * Return the index page
   */
  return (
    <Layout 
      showAppStoreLinks={showAppStoreLinks} 
      setShowAppStoreLinks={setShowAppStoreLinks}>
      <Seo 
        title="Home"
        description="Welcome to the world of TECSight"
      />
      <CTAButtons/>
      <RootLinks/>
      <IntroText/>
    </Layout>
  )
}

export default IndexPage
